import React, { useState, useRef, useEffect } from "react";
import { Stack, PrimaryButton } from "@fluentui/react";
import styles from "./AdminPage.module.css";
import { disableAPI, getCurrentVersion } from "../../api/api";
import { TwoButtonDialog, TwoButtonDialogHandle, ErrorDialog, ErrorDialogHandle } from "../../components/Dialogs";

export const AdminPage: React.FC = () => {
    const [reloadRequired, setReloadRequired] = useState(false);
    const [isVisible, setIsVisible] = useState(document.visibilityState === 'visible');
    const versionDialogRef = useRef<TwoButtonDialogHandle | null>(null);
    const errorDialogRef = useRef<ErrorDialogHandle | null>(null);

    // update the local version to current App Version
    const updateCurrentVersion = async () => {
        try {
            const currentVersion : string = await getCurrentVersion();
            localStorage.setItem('appVersion', currentVersion);
        } catch (error) {
            // catch local storage overflow error
            if (errorDialogRef.current) {
                errorDialogRef.current.handleClickOpen();
            }
        }
    };
    // This will trigger upon reload and re-render! We have to be careful for the case when users navigate away and back again
    // Therefore, we do not enable the API here, but instead update the version and set reload required to false
    // so that if this was a reload the error dialog is disabled and local version updated.
    // If a re-render triggers this, the API will still be disabled until the user reloads, so it is ok to update the version preemptively.
    useEffect(() => {
        setReloadRequired(false);
        updateCurrentVersion();
    }, []);

    useEffect(() => {
        if (reloadRequired) {
            if (versionDialogRef.current) {
                versionDialogRef.current.handleClickOpen();
            }
        }
    }, [reloadRequired])

    useEffect(() => {
        function handleVisibilityChange() {
            setIsVisible(document.visibilityState === 'visible');
        }
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {document.removeEventListener('visibilitychange', handleVisibilityChange)};
    }, []);

    useEffect(() => {
        if (isVisible) {
            compareVersion();
        }
    }, [isVisible]);

    const compareVersion = async () => {
        if (document.visibilityState === 'visible') {
            // check the version
            const currentVersion: String = await getCurrentVersion();
            const localVersion: String = localStorage.getItem('appVersion') || "undefined";
            if (currentVersion !== localVersion) {
                // alert the user
                setReloadRequired(true);
            }
        }
    }

    const handleVersionUpdateRequired = (flag : boolean) => {
        if (flag) {
            window.location.reload();
        } else {
            disableAPI();
        }
    }

    return (
        <div className={styles.Container}>
            <Stack className={styles.MenuContainer} horizontal horizontalAlign="center" tokens={{ childrenGap: 10 }}>
              <PrimaryButton
                  id="pm_report_button"
                  text="Prompt Metrics Report"
                  onClick={() => window.open('https://app.powerbi.com/groups/b0f24f79-95b6-4827-8841-f1c7cd298fd7/reports/2a2b38fb-5adb-4fa9-93fd-dd1ca97b0988/0bc9ac068c9288e56054?experience=power-bi', '_blank')} />
              <PrimaryButton
                  id="uf_report_button"
                  text="User Feedback Report"
                  onClick={() => window.open('https://app.powerbi.com/groups/b0f24f79-95b6-4827-8841-f1c7cd298fd7/reports/2a2b38fb-5adb-4fa9-93fd-dd1ca97b0988/b72568aa764ad2c6fbe4?experience=power-bi', '_blank')} />
            </Stack>
            <ErrorDialog
                ref={errorDialogRef}
                title="Memory Limit Reached"
                message="The local memory limit has been reached. Please delete a chat to free up memory."
            />
            <TwoButtonDialog
                ref={versionDialogRef}
                title="Update Required"
                message="Your version of Co-op GPT is out of date, please refresh the page to get the latest version. Don't worry, your saved chats will still be available."
                callback={handleVersionUpdateRequired}
                cancelText="Give me a sec"
                confirmText="Refresh Now"
            />
        </div>
    );
};
