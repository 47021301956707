import { Text } from "@fluentui/react";
import { PersonLock24Regular } from "@fluentui/react-icons";
import { useNavigate } from 'react-router-dom';
import styles from "./AdminButton.module.css";

interface Props {
  className?: string;
}

export const AdminButton = ({ className }: Props) => {
  const navigate = useNavigate();

  return (
    <div
      className={`${styles.container} ${className ?? ""}`}
      onClick={() => navigate('/admin')}
      title="To access or see the Admin button, you will need to have permission set in the app service. Current permission is 'Manage.ALL'."
      id="admin-button"
    >
      <PersonLock24Regular />
      <Text className={styles.container}>{"Admin"}</Text>
    </div>
  );
};
